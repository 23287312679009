



































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { FundProjectDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundProjectProgress",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectProgress extends Vue {
  @Ref() readonly pagedTableView!: any;
  tableItems: FundProjectDto[] = [];
  statusList: any = [];
  projectType: any = [];

  queryForm: any = {
    name: "",
    projectType: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
    await api.enumService
      .getValues({
        typeName: "FundProjectType",
      })
      .then((res) => {
        this.projectType = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundProject.getAll(params);
  }

  // 项目进展管理
  jumpDetail(row: FundProjectDto) {
    this.$router.push({
      name: "fundProjectProgressList",
      params: { projectId: row.id!.toString() },
    });
  }
}
